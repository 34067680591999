import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Heading, Link, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";

const Index = () =>
  <Layout>
    <Helmet>
      <title>Privacy Policy</title>
    </Helmet>

    <Heading>Privacy Policy for image-watch.com</Heading>

    <Text>This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from https://image-watch.com (the "Site").</Text>

    <Text>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in image-watch.com. This policy is not applicable to any information collected offline or via channels other than this website.</Text>

    <Heading size="lg">Consent</Heading>

    <Text>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</Text>

    <Heading size="lg">Personal information we collect</Heading>

    <Text>When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site.</Text>

    <Text>We automatically collect information using the following technologies:</Text>

    <UnorderedList>
      <ListItem>Cookies are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit <Link href="https://www.allaboutcookies.org/" isExternal>https://www.allaboutcookies.org/ <ExternalLinkIcon /></Link>.</ListItem>
      <ListItem>Log files track actions occurring on the Site, and collect data including your IP address, browser type, referring/exit pages, and date/time stamps.</ListItem>
    </UnorderedList>

    <Text>Additionally, when you make a purchase or attempt to make a purchase through the Site, we collect certain information from you, including your name, billing address, payment information (including credit card numbers or bank details), email address, and phone number.</Text>

    <Text>If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</Text>

    <Text>When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.</Text>

    <Heading size="lg">How we use your information</Heading>

    <Text>We use the information we collect in various ways, including to:</Text>

    <UnorderedList>
      <ListItem>Provide, operate, and maintain the Site</ListItem>
      <ListItem>Fulfill orders placed through the Site (including processing your payment information and providing you with invoices and/or order confirmations)</ListItem>
      <ListItem>Improve, personalize, optimize, and expand the Site</ListItem>
      <ListItem>Understand and analyze how you use the Site</ListItem>
      <ListItem>Develop new products, services, features, and functionality</ListItem>
      <ListItem>Communicate with you, either directly or through one of our partners, including for customer service or to provide you with updates and other information relating to the Site</ListItem>
      <ListItem>Send you emails</ListItem>
      <ListItem>Screen for potential risk or fraud</ListItem>
      <ListItem>When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services</ListItem>
    </UnorderedList>

    <Heading size="lg">Sharing your personal information</Heading>

    <Text>We share your Personal Information with third parties to help us use your Personal Information, as described above. We use Auth0 to manage logins; you can read more about how Auth0 uses your Personal Information here: <Link href="https://auth0.com/privacy" isExternal>https://auth0.com/privacy <ExternalLinkIcon /></Link>. We use Stripe to manage orders and subscriptions; you can read more about how Stripe uses your Personal Information here: <Link href="https://stripe.com/privacy" isExternal>https://stripe.com/privacy <ExternalLinkIcon /></Link>. We use SendGrid to send emails; you can read more about how SendGrid uses your Personal Information here: <Link href="https://www.twilio.com/legal/privacy" isExternal>https://www.twilio.com/legal/privacy <ExternalLinkIcon /></Link>. We also use Google Analytics to help us understand how our customers use the Site; you can read more about how Google uses your Personal Information here: <Link href="https://www.google.com/intl/en/policies/privacy/" isExternal>https://www.google.com/intl/en/policies/privacy/ <ExternalLinkIcon /></Link>. You can also opt-out of Google Analytics here: <Link href="https://tools.google.com/dlpage/gaoptout" isExternal>https://tools.google.com/dlpage/gaoptout <ExternalLinkIcon /></Link>.</Text>

    <Text>Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.</Text>

    <Text>Note that image-watch.com has no access to or control over cookies that are used by these partners.</Text>

    <Heading size="lg">Do not track</Heading>

    <Text>Please note that we do not alter our Site's data collection and use practices when we see a Do Not Track signal from your browser.</Text>

    <Heading size="lg">CCPA privacy rights</Heading>

    <Text>If you are a California resident, you have the right to access personal information we hold about you and to ask that your personal information be deleted. If you would like to exercise this right, please contact us through the contact information below.</Text>

    <Heading size="lg">GDPR data protection rights</Heading>

    <Text>If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.</Text>

    <Text>Additionally, if you are a European resident, we note that we are processing your information in order to pursue our legitimate business interests listed above. Additionally, please note that your information will be transferred outside of Europe, in particular, to the United States.</Text>

    <Heading size="lg">Data retention</Heading>

    <Text>When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.</Text>

    <Heading size="lg">Changes</Heading>

    <Text>We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.</Text>

    <Heading size="lg">Contact us</Heading>

    <Text>For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at support@image-watch.com or by mail using the details provided below:</Text>

    <Text>P.O. Box 196, Williamsburg, MI, 49690, United States</Text>

  </Layout>;

export default Index;